@import "common/_variables";
@import "common/_mixins";

#login {
    h1 {
        a {
            background-image: url('../images/orijin-logo.png');
            height: 105px;
            margin: 0;
            width: 150px;

            @include image-2x('../images/orijin-logo@2x.png', 150px, 105px);
        }
    }
}
