@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

@mixin font-size($sizeValue: 12 ){
  font-size: $sizeValue + px; //fallback for old browsers
  font-size: (0.1 * $sizeValue) + rem;
}

@mixin bgTransparent($rgbColor: rgb(0,0,0), $opacity: 0.8) {
    $r: red($rgbColor);
    $g: green($rgbColor);
    $b: blue($rgbColor);
    $transColor: rgba($r, $g, $b, $opacity);

    background-color: $transColor;
}

@mixin borderTransparent($rgbColor: rgb(0,0,0), $opacity: 0.8) {
    $r: red($rgbColor);
    $g: green($rgbColor);
    $b: blue($rgbColor);
    $transColor: rgba($r, $g, $b, $opacity);

    border-color: $transColor;
}

@mixin linkUnderlined($rgbColor: rgb(0,0,0), $underlineWeight: 1rem) {
    $r: red($rgbColor);
    $g: green($rgbColor);
    $b: blue($rgbColor);
    $underlineColor: rgba($r, $g, $b, .1);
    $underlineColorHover: rgba($r, $g, $b, .2);
    $underlineFactor: -$underlineWeight;
    $underlineShadow: inset 0 $underlineFactor 0 $underlineColor;
    $underlineShadowHover: inset 0 $underlineFactor 0 $underlineColorHover;

    a {
        border-bottom: 1px solid $underlineColor;
        box-shadow: $underlineShadow;
        color: $rgbColor;
        /*display: inline-block;*/
        line-height: 0.95;
        text-decoration: none !important;
        transition: all $timing ease;

        &:hover {
            border-bottom: 1px solid $underlineColorHover;
            box-shadow: $underlineShadowHover;
        }
    }
}


@mixin nav-list {
    list-style-type:none;
    padding:0;
    margin:0;
    overflow:hidden;
    > li{
        display:block;
        float:left;
        &:last-child{
            margin-right:0px;
        }
    }
}


/*@mixin sprite-icons($x, $y) {
    $xpos: $x + px;
    $ypos: $y + px;
    background: url('../images/icons-sprite.png') no-repeat $xpos $ypos transparent;
}

@mixin sprite-icons-2x {
    background-image: url('../images/icons-sprite@2x.png');
    background-size: 145px 329px;
}

@mixin sprite-logo($x, $y) {
    $xpos: $x + px;
    $ypos: $y + px;
    background: url('../images/logo_sprite.png') no-repeat $xpos $ypos transparent;
}

@mixin sprite-logo-2x {
    background-image: url('../images/logo_sprite@2x.png');
    background-size: 822px 137px;
}
*/
